export default function swipersSections() {
    const swiperServicosSobre = new Swiper(".swiper-servicos-sobre", {
        slidesPerView: 2.5,
        spaceBetween: 16,
        loop: true,
        speed: 5000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        pagination: {
          el: "#sobre .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
        640: {
            slidesPerView: 4.2,
            spaceBetween: 16,
        },
        768: {
            slidesPerView: 5.2,
            spaceBetween: 16,
        },
        1024: {
            slidesPerView: 7,
            spaceBetween: 16,
        },
        },
    });

  const swiperClientes = new Swiper(".swiper-clientes:not(.linha-2)", {
    slidesPerView: 2,
    spaceBetween: 16,
    centeredSlides: true,
    centeredSlidesBounds: true,
    loop: true,
    speed: 5000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      1900: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
    },
  });

  const swiperClientes2 = new Swiper(".swiper-clientes.linha-2", {
    slidesPerView: 2,
    spaceBetween: 16,
    loop: true,
    speed: 5000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3.5,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      1900: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
    },
  });

  const swiperProjetos = new Swiper(".swiper-projetos-3d", {
    slidesPerView: 1.2,
    spaceBetween: 16,
    pagination: {
      el: "#projetos_3d .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // loop: true,
    // speed: 5000,
    // autoplay: {
    //   delay: 0,
    //   disableOnInteraction: false,
    // },
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      1900: {
        slidesPerView: 5,
        spaceBetween: 16,
      },
    },
  });



  const swiperCases = new Swiper(".swiper-cases", {
    slidesPerView: 1.3,
    spaceBetween: 16,
    loop: true,
    speed: 8000,
     autoplay: {
       delay: 0,
       disableOnInteraction: false,
       reverseDirection: true,
     },
    breakpoints: {
      500: {
        slidesPerView: 1.5,
        spaceBetween: 16,
      },
      600: {
        slidesPerView: 1.6,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2.2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 2.8,
        spaceBetween: 16,
      }
    },
  });

  const swiperServicos = new Swiper(".swiper-servicos", {
    slidesPerView: "auto",
    spaceBetween: 0,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
    },
    // breakpoints: {
    //   768: {
    //     slidesPerView: 2.2,
    //     spaceBetween: 16,
    //   },
    //   1024: {
    //     slidesPerView: 3.2,
    //     spaceBetween: 16,
    //   }
    // },
  });

  const swiperCasesBriefind = new Swiper(".swiper-cases-briefind", {
    slidesPerView: 1.3,
    spaceBetween: 16,
    pagination: {
      el: "#briefind-cases .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

      loop: true,
      speed: 8000,
      autoplay: {
      delay: 0,
      disableOnInteraction: false,
     },

    breakpoints: {
      600: {
        slidesPerView: 1.6,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 1.8,
        spaceBetween: 16,
      },
      900: {
        slidesPerView: 2.2,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 2.4,
        spaceBetween: 16,
      },
      1200:{
        slidesPerView: 2.8,
        spaceBetween: 16,
      },
      1440: {
        slidesPerView: 2.9,
        spaceBetween: 16,
      },
      1600: {
        slidesPerView: 3.2,
        spaceBetween: 16,
      },
      1900:{
        slidesPerView: 3.8,
        spaceBetween: 16,
      }
    },
  });
  
  const breakpoint = window.matchMedia("(min-width:1024px)");
  let swiperPortfolio;

  const breakpointChecker = function () {
    if (breakpoint.matches === true) {
      if (swiperPortfolio !== undefined) swiperPortfolio.destroy(true, true);

      return;
    } else if (breakpoint.matches === false) {
      return enableSwiper();
    }
  };

  const enableSwiper = function () {
    swiperPortfolio = new Swiper(".swiper-portfolio", {
        slidesPerView: 1.2,
        spaceBetween: 8,
        loop: true,
        speed: 10000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        pagination: {
          el: "#portfolio .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
        768: {
            slidesPerView: 2.2,
            spaceBetween: 8,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 8,
        },
        },
    });
  };

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
}