import menu from "./menu.js";
import swipersSections from "./swipers.js";
import contatoForm from "./contatoForm.js";
import mostrarSenha from "./mostrarSenha.js";
import toggleMobilePortfolio from "./toggleMobilePortfolio.js";
import loader from "./loaderBase.js"
import projetos3d from "./projetos_3d_home.js";
import handlePasswordModal from "./handlePasswordModal.js";
import slideServicos from "./slideServicos.js";

menu();
swipersSections();
contatoForm();
mostrarSenha();
toggleMobilePortfolio();
projetos3d();
window.myLoader = loader;

window.onload = function() {
  window.myLoader().hide()
  const loaders = document.querySelectorAll(".loader");
  loaders.forEach (loader => loader.style.display = "none")
  document.body.classList.remove("loading");
};

document.onreadystatechange = function () {
  const state = document.readyState
  if (state == 'interactive') {
       document.body.classList.add("loading");
  } else if (state == 'complete') {
      setTimeout(function(){
         document.body.classList.remove("loading");
      },1000);
  }
}

// PAGES
const pageHome = document.querySelector(".body-home");
const pageProposta = document.querySelector(".body-proposta");
const pageBriefing = document.querySelector(".body-briefing");


const btnModal = document.querySelector('.btn-submit')

// ★ Pages Scopo
if (pageHome) {


}

else if (pageProposta || pageBriefing){
  btnModal.addEventListener('click',()=>{
    AOS.init({
      disable: function () {
        var maxWidth = 768;
        return window.innerWidth < maxWidth;
      },
      startEvent: "DOMContentLoaded",
      offset: 30, // offset (in px) from the original trigger point
      delay: 100, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
    });
    console.log('caiu no click')
  })
}

if (window.AOS && (!pageProposta && !pageBriefing)) {
  console.log('AOS')
  AOS.init({
    disable: function () {
      var maxWidth = 768;
      return window.innerWidth < maxWidth;
    },
    startEvent: "DOMContentLoaded",
    offset: 30, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
  });
}

document.addEventListener("DOMContentLoaded", () => document.body.classList.add("dcl"));


if (pageProposta) {
  window.navSwiper = new Swiper({
    slidesPerView: 'auto',
    spaceBetween: 8,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next-servicos",
      prevEl: ".swiper-button-prev-servicos",
    },
  });

  // handlePasswordModal().init()
  slideServicos({ handleClick: true }).init("servico-1", true)
}

if (pageBriefing || pageProposta) {
  handlePasswordModal().init();
}

document.addEventListener("DOMContentLoaded", () =>
  document.body.classList.add("dcl")
);
