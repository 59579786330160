export default function handlePasswordModal() {
  const modalContainer = document.querySelector("[js-modal]");
  const modalForm = modalContainer.querySelector("#form_senha");
  const passwordInput = modalForm.querySelector("#input_senha");
  const conteudo = document.querySelector("#content-containe");
  const loaderContainer = document.querySelector(".loader--container");
  const inputPassword = document.querySelector("#modal-password");
  
  const closeModal = () => {
    modalContainer.classList.add("closed");
    document.querySelector('html').style.overflowY = "auto";
    conteudo.style.opacity = "1";
    loaderContainer?.style.display = "block";
    setTimeout(() => modalContainer.remove(), 1000);
  };

  const verifyPasswordValue = () => {
    const inputBefore = modalForm.querySelector(".input--field");
    const passwordValue = inputPassword.value;

    if (passwordInput.value === passwordValue) {
      closeModal();
    } else {
      inputBefore.style.setProperty("--bg-before", "#AB2E46");
      inputBefore.nextElementSibling.style.display = "block";
      passwordInput.focus();
    }
  };

  const events = () => {
    modalForm.addEventListener("submit", (e) => {
      e.preventDefault();
      verifyPasswordValue();
    });

    passwordInput.addEventListener("keyup", (e) => {
      if (e.keyCode === 13) {
        modalForm.querySelector('button[type="submit"]').click();
      }
    });
  };

  function init() {
    modalContainer.classList.remove("closed");
    // window.scrollTo({ top: 0, behavior: 'smooth' })
    document.querySelector('html').style.overflow = "hidden";
    events();
    setTimeout(() => {
      passwordInput.focus();
    }, 300);

    return this;
  }

  return { init };
}
