export default function toggleMobilePortfolio() {
    const cards = document.querySelectorAll(".card");

    cards && cards.forEach(card => card.addEventListener('click', handleCardClick))

    function handleCardClick(event){
        const card = event.currentTarget
        const isActive = card.classList.contains('active') ? true : false
        cards.forEach(card => card.classList.remove('active'))
        card.classList.toggle('active', !isActive)
    }
}